import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { IBeneficio } from './interfaces/beneficio.interface';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  selector: 'mm12-cardbeneficio',
  templateUrl: './cardbeneficio.component.html',
  styleUrls: ['./cardbeneficio.component.scss'],
})
export class CardbeneficioComponent implements OnInit {

  @Input() beneficio!: IBeneficio;

  constructor(public router: Router) { }

  ngOnInit() { }

  emBreve() {
    alert("Em breve!")
  }

}
