import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TemplateComponent } from './layouts/template/template.component';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: TemplateComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
        // canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToHome }
      },
      {
        path: 'voucher',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
      },
      {
        path: 'sala',
        loadChildren: () => import('./pages/sala/sala.module').then( m => m.SalaPageModule)
      },
      {
        path: 'programa',
        loadChildren: () => import('./pages/programa/programa.module').then(m => m.ProgramaPageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'noticias',
        loadChildren: () => import('./pages/noticias/noticias.module').then(m => m.NoticiasPageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'minhaconta',
        loadChildren: () => import('./pages/minhaconta/minhaconta.module').then(m => m.MinhacontaPageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'faleconosco',
        loadChildren: () => import('./pages/faleconosco/faleconosco.module').then(m => m.FaleconoscoPageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'extrato',
        loadChildren: () => import('./pages/extrato/extrato.module').then(m => m.ExtratoPageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'missoes',
        loadChildren: () => import('./pages/missoes/missoes.module').then(m => m.MissoesPageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'noticia/:id',
        loadChildren: () => import('./pages/noticia/noticia.module').then(m => m.NoticiaPageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'noticia',
        loadChildren: () => import('./pages/noticia/noticia.module').then(m => m.NoticiaPageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'resgates',
        loadChildren: () => import('./pages/resgates/resgates.module').then(m => m.ResgatesPageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
      },
      {
        path: 'relatorios',
        loadChildren: () => import('./pages/relatorios/relatorios.module').then( m => m.RelatoriosPageModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'ranking',
        loadChildren: () => import('./pages/ranking/ranking.module').then( m => m.RankingPageModule)
      },
      {
        path: 'backoffice',
        loadChildren: () => import('./pages/backoffice/backoffice.module').then( m => m.BackofficePageModule)
      }
    ]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
