import { Component, OnInit,HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  selector: 'mm12-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  public logged = false;
  public unloggedLinks = [
    { "link": "<img src='../../../assets/imgs/header/faleConosco.png'> Fale Conosco", "route": "/faleconosco" },
    { "link": "<img src='../../../assets/imgs/header/faq.png'> Dúvidas Frequentes", "route": "/faq" },
  ];
  public loggedLinks = [
    { "link": "Home", "route": "/home" },
    { "link": "O Programa", "route": "/programa" },
    // { "link": "Resgates", "route": "/resgates" },
    { "link": "Notícias", "route": "/noticias" },
    { "link": "Missões", "route": "/missoes" },
    { "link": "Minha Conta", "route": "/minhaconta" },
    { "link": "Extrato", "route": "/extrato" }
  ];
  
  user = {};
  isMobile = window.innerWidth < 1024;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.isMobile = window.innerWidth < 1024;
  }

  public isLogged = false;

  isOpenMenu = false;
  
  constructor(
    public router: Router,
    private auth: AngularFireAuth,
    private db: AngularFirestore
  ) { }
  
  async ngOnInit() {
    let env = this;
    await this.auth.onAuthStateChanged(usr => {
      if (usr) {
        this.logged = true;
        this.db.collection('users').doc(usr.uid).ref.get().then(userDoc => {
          if (userDoc.exists) {
            this.user = userDoc.data()
          }
        })
      } else {
        this.logged = false;
      }
    })
  }

  footerLinkClick(str) {

      this.router.navigate([str]);
  }

  logout() {
    this.isOpenMenu = false;
    this.auth.signOut().then(() => {
      this.isLogged = false;
      this.router.navigate(['/login']);
    });
  }
}
