import { Component } from '@angular/core';
import { Loading } from 'notiflix';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() {
    Loading.init({'svgColor': '#FDBA12'})
  }
}
