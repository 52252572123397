// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAKghStYQy76bR_K_z__DnfORzTNAPF4t8",
    authDomain: "sotreq-conquiste-mais.firebaseapp.com",
    projectId: "sotreq-conquiste-mais",
    storageBucket: "sotreq-conquiste-mais.appspot.com",
    messagingSenderId: "177052416325",
    appId: "1:177052416325:web:d7cc82b8f43f35937f262c"
  },
  catalogoUrl: 'https://catalogomm12.com.br:8990'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
